import { useEffect, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Project from "./project";
import Header from "./header";
import { Drawer } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";

export default function Home() {
  gsap.registerPlugin(ScrollTrigger);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [projectSelected, setProjectSelected] = useState([]);

  const [cursorPositions, setCursorPositions] = useState({});

  const [globalMouse, setGlobalMouse] = useState(0);

  const handleMouseMoveGlobal = (e) => {
    setGlobalMouse({ width: e.clientX, height: e.clientY });
  };

  const updateCursorPosition = (projectId, position) => {
    setCursorPositions((prevPositions) => ({
      ...prevPositions,
      [projectId]: position,
    }));
  };

  const handleOpenDrawer = (e) => {
    setOpenDrawer(!openDrawer);
  };

  const projects = [
    {
      title: "Porte Moi",
      description: "Application web pour acheter des vêtements",
      image: "../PorteMoi/home.png",
      images: [
        "../PorteMoi/home.png",
        "../PorteMoi/cart.png",
        "../PorteMoi/commands.png",
        "../PorteMoi/login.png",
        "../PorteMoi/register.png",
      ],
      technos: [
        "ReactJS en Front",
        "NodeJS en Back",
        "Mysql en BDD",
        "MaterialUI pour le design",
        "Redux pour la gestion des états",
        "Toast pour les notifications",
      ],
      goals: [
        "Créer une application web de vente de vêtements avec un système de panier et de commandes",
        "Créer un système de connexion et d'inscription",
        "Un utilisateur peut ajouter des vêtements à son panier et passer commande",
        "Un utilisateur peut voir ses commandes",
      ],
      link: "https://github.com/Alexis-Lu/thebradery",
    },
    {
      title: "Interior Design Minimalist",
      description:
        "Intégration d'une maquette Figma d'un site de design minimaliste d'intérieur",
      image: "../InteriorDesign/home.png",
      images: [
        "../InteriorDesign/home.png",
        "../InteriorDesign/arrivals.png",
        "../InteriorDesign/design.png",
        "../InteriorDesign/caroussel.png",
        "../InteriorDesign/footer.png",
      ],
      technos: ["ReactJS en Front", "MaterialUI pour le design"],
      goals: [
        "Intégrer une maquette Figma que j'ai récupérée sur le site https://www.figma.com/",
      ],
      video: "../InteriorDesign/video.mov",
      link: "https://interior-design-minimalist.web.app/",
    },
    {
      title: "Facebook Clone",
      description: "Clone de Facebook avec ReactJS et MaterialUI",
      image: "../FacebookClone/home.png",
      images: ["./FacebookClone/login.png", "../FacebookClone/home.png"],
      technos: ["ReactJS en Front", "MaterialUI pour le design"],
      goals: [
        "Créer une application web qui ressemble à Facebook avec un système de connexion et d'inscription",
        "Un utilisateur peut créer un post",
      ],
      link: "https://github.com/Alexis-Lu/facebook-clone",
    },
    {
      title: "Charge-in",
      description: "Intégration d'une maquette Figma pour un test technique",
      image: "../ChargeIn/home.png",
      images: [
        "../ChargeIn/login.png",
        "../ChargeIn/home.png",
        "../ChargeIn/home2.png",
        "../ChargeIn/houses.png",
        "../ChargeIn/infos.png",
        "../ChargeIn/notifications.png",
      ],
      technos: [
        "ReactJS en Front",
        "MaterialUI pour le design",
        "Redux pour la gestion des états",
      ],
      goals: ["Intégrer une maquette Figma pour un test technique"],
    },
    {
      title: "The Hyrule Castle",
      description: "Jeu vidéo Zelda en ligne de commande en TypeScript",
      image: "../TheHyruleCastle/start.png",
      images: [
        "../TheHyruleCastle/start.png",
        "../TheHyruleCastle/mid.png",
        "../TheHyruleCastle/end.png",
      ],
      technos: ["TypeScript"],
      goals: [
        "Créer un jeu vidéo Zelda en ligne de commande",
        "Créer un système de combat avec des étages",
        "Chaque étages contient un boss aléatoire",
      ],
    },
    {
      title: "MyBubbleTea",
      description: "Application web pour acheter des bubble tea",
      image: "../MyBubbleTea/home.png",
      images: [
        "../MyBubbleTea/home.png",
        "../MyBubbleTea/login.png",
        "../MyBubbleTea/cart.png",
        "../MyBubbleTea/products.png",
        "../MyBubbleTea/addToCart.png",
        "../MyBubbleTea/recap.png",
      ],
      technos: ["Laravel"],
      goals: [
        "Créer une application web de vente de bubble tea avec un système de panier et de commandes",
        "Créer un système de connexion et d'inscription",
        "Un utilisateur peut ajouter des bubble tea à son panier et passer commande",
        "Un utilisateur peut voir ses commandes",
      ],
    },
    {
      title: "Les Arcs",
      description:
        "Site web de la station de ski Les Arcs (en cours de développement)",
      image: "../LesArcs/home.png",
      images: [],
      video: "../LesArcs/video.mov",
      technos: [
        "ReactJS en Front",
        "MaterialUI pour le design",
        "TailwindCSS pour le CSS",
      ],
      goals: [
        "Recréer le site web de la station de ski Les Arcs",
        "Plus tard ajouter des fonctionnalitées commes des commandes de forfaits, de locations de ski, etc...",
      ],
      link: "",
    },
  ];
  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMoveGlobal);
    return () => {
      window.removeEventListener("mousemove", handleMouseMoveGlobal);
    };
  }, []);

  return (
    <div
      style={{
        height: "100%",
        width: "100vw",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "5%",
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <h1
          class="title"
          style={{
            maxHeight: "100vh",
            height: "100%",
            writingMode: "vertical-rl",
            textOrientation: "sideways",
            fontSize: "1.5rem",
            color: "#191919",
            position: "fixed",
            fontWeight: "300",
            margin: "0 auto",
            textAlign: "center",
            top: "50%",
            transform: "translateY(-50%) scale(-1)",
          }}
        >
          Alexis Lu - Développeur FullStack
        </h1>
      </div>

      <div style={{ width: "100%", margin: "50px 0px" }}>
        <Header />
        {projects.map((item, key) => {
          return (
            <div
              onClick={() => {
                setProjectSelected(item);
                handleOpenDrawer();
              }}
            >
              <Project
                title={item.title}
                description={item.description}
                image={item.image}
                projectId={key}
                key={key}
                updateCursorPosition={updateCursorPosition}
                globalMouse={globalMouse}
                onClick={() => {
                  setProjectSelected(item);
                  handleOpenDrawer();
                }}
              />
            </div>
          );
        })}
        <Drawer anchor="bottom" open={openDrawer} onClose={handleOpenDrawer}>
          <div
            style={{
              minHeight: "80vh",
              maxHeight: "80vh",
              minWidth: "100%",
              backgroundColor: "white",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <h1 style={{ fontSize: "2em" }}>{projectSelected.title}</h1>
            </div>
            {projectSelected.video ? (
              <video
                autoPlay={true}
                muted={true}
                loop={true}
                src={projectSelected.video}
                style={{
                  opacity: 1,
                  width: "auto",
                  height: "auto",
                  border: "10px solid black",
                }}
              ></video>
            ) : (
              ""
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                flexDirection: "row",
                padding: "0 50px",
              }}
            >
              <div style={{ textTransform: "none", fontFamily: "Roboto" }}>
                <h1>Objectifs</h1>
                {projectSelected.goals
                  ? projectSelected.goals.map((item, index) => {
                      return (
                        <li
                          key={index}
                          style={{
                            fontSize: "1.5em",
                            padding: "10px 0",
                          }}
                        >
                          {item}
                        </li>
                      );
                    })
                  : ""}
                <h1>Technologies utilisées</h1>
                {projectSelected.technos
                  ? projectSelected.technos.map((item, index) => {
                      return (
                        <li
                          key={index}
                          style={{ padding: "10px 0", fontSize: "1.5em" }}
                        >
                          {item}
                        </li>
                      );
                    })
                  : ""}
                <h1>
                  {projectSelected.link ? (
                    <a
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={() => window.open(projectSelected.link)}
                    >
                      Lien
                      <LaunchIcon />
                    </a>
                  ) : (
                    ""
                  )}
                </h1>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                {projectSelected.images
                  ? projectSelected.images.map((item, index) => {
                      return (
                        <img
                          key={index}
                          src={item}
                          style={{
                            maxWidth: "50em",
                            height: "auto",
                            border: "1px solid black",
                            borderRadius: "10px",
                            margin: "20px 0",
                            transition: "transform 0.3s",
                          }}
                        />
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>
        </Drawer>
      </div>
    </div>
  );
}
