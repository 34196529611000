import { Button, Drawer, Box } from "@mui/material";
import { useEffect, useState } from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
export default function Header() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const skills = {
    coding: [
      "JavaScript",
      "TypeScript",
      "HTML",
      "CSS",
      "PHP",
      "Python",
      "Java",
      "SQL",
    ],
    front: [
      "ReactJS",
      "Angular",
      "Laravel",
      "MVC",
      "Material-UI",
      "TailwindCSS",
      "Redux",
    ],
    back: ["NodeJS", "SpringBoot", "API Rest", "ExpressJs", "Laravel"],
    database: ["Mysql", "Postgresql"],
    methods: ["Agile Scrum"],
    versioning: ["Git"],
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {}, [isDrawerOpen]);

  return (
    <div
      style={{
        width: "100vw",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        position: "fixed",
      }}
    >
      <div style={{ filter: "blur(1.5px)", fontSize: "1.5em" }}>
        @ Alexis Lu - 2023
      </div>
      <div
        style={{
          border: "1px solid black",
          borderRadius: "0.5em",
          position: "relative",
        }}
      >
        <Button
          sx={{
            color: "black",
            fontSize: "1em",
          }}
          onClick={toggleDrawer}
        >
          A propos
        </Button>
        <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer}>
          <Box
            sx={{
              width: "50vw",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "flex-start",
              margin: "0px 50px",
              height: "100%",
              textTransform: "none",
              fontFamily: "Georgia",
            }}
          >
            <h1
              style={{
                fontSize: "1.5em",
                display: "flex",
                flexWrap: "wrap",
                fontWeight: "normal",
              }}
            >
              Je m'appelle Alexis Lu et je suis un passionné de développement
              web avec une expertise en tant que Développeur FullStack.
            </h1>

            <p style={{ fontSize: "1.3em" }}>
              Actuellement en{" "}
              <span style={{ fontWeight: "bold" }}>
                Master Of Science 1 Architecte Logiciel, Développeur
                D’application - IDV
              </span>
              {""} à l'<span style={{ fontWeight: "bold" }}>ETNA</span>
            </p>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "100%",
              }}
            >
              <div>
                <h2>Languages</h2>
                {skills.coding
                  ? skills.coding.map((item) => {
                      return (
                        <p style={{ fontSize: "1.2em", margin: 0 }}>{item}</p>
                      );
                    })
                  : ""}
              </div>
              <span style={{ border: "1px solid gray", height: "100%" }}></span>
              <div>
                <h2>Frontend</h2>
                {skills.front
                  ? skills.front.map((item) => {
                      return (
                        <p style={{ fontSize: "1.2em", margin: 0 }}>{item}</p>
                      );
                    })
                  : ""}
              </div>
              <span style={{ border: "1px solid gray", height: "100%" }}></span>
              <div>
                <h2>Backend</h2>
                {skills.back
                  ? skills.back.map((item) => {
                      return (
                        <p style={{ fontSize: "1.2em", margin: 0 }}>{item}</p>
                      );
                    })
                  : ""}
              </div>
              <span style={{ border: "1px solid gray", height: "100%" }}></span>
              <div>
                <h2>Base de données</h2>
                {skills.database
                  ? skills.database.map((item) => {
                      return (
                        <p style={{ fontSize: "1.2em", margin: 0 }}>{item}</p>
                      );
                    })
                  : ""}
              </div>
            </div>

            <div
              style={{
                border: "1px solid black",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "auto",
                height: "auto",
                padding: "20px 20px",
                marginTop: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <div>Alexis</div>
                    <div style={{ marginLeft: "3px" }}>Lu</div>
                  </div>
                  <div>Développeur FullStack</div>
                </div>
                <div>
                  <img src="/logo.png" style={{ width: "60px" }} />
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Gmail_icon_%282020%29.svg/1280px-Gmail_icon_%282020%29.svg.png"
                    style={{
                      width: "18px",
                      height: "16px",
                      margin: "0px 3px",
                      textAlign: "center",
                    }}
                  ></img>
                  : alexis.lu.dev@gmail.com
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    window.open(
                      "https://www.linkedin.com/in/alexis-l-866a0616a/"
                    );
                  }}
                >
                  <LinkedInIcon style={{ color: "#0077B5" }} />:
                  https://www.linkedin.com/in/alexis-l-866a0616a/
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    window.open("https://github.com/Alexis-Lu");
                  }}
                >
                  <GitHubIcon /> : https://github.com/Alexis-Lu
                </div>
              </div>
            </div>
          </Box>
        </Drawer>
      </div>
    </div>
  );
}
