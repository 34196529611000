import React, { useState, createRef, useLayoutEffect } from "react";
import gsap from "gsap";
import "./project.css";

export default function Project({
  title,
  description,
  image,
  projectId,
  updateCursorPosition,
  globalMouse,
}) {
  const [followerPosition, setFollowerPosition] = useState({});
  const cursorRef = createRef();

  const getWindowSize = () => {
    return { width: window.innerWidth, height: window.innerHeight };
  };

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const handleMouseMove = (e) => {
    const rect = cursorRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    cursorRef.current.style.setProperty("--x", `${x}px`);
    cursorRef.current.style.setProperty("--y", `${y}px`);

    setFollowerPosition({ x, y });
    updateCursorPosition(projectId, { x, y });
  };

  useLayoutEffect(() => {
    const cursor = cursorRef.current;
    gsap.to(cursor, {
      duration: 1,
      ease: "power3.out",
      x: globalMouse.width - windowSize.width / 2 - 30,
      y: followerPosition.y - 220,
    });
  }, [globalMouse, followerPosition, cursorRef]);

  return (
    <div className="container" onMouseMove={handleMouseMove}>
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <a>
          <h1
            style={{
              fontSize: "3rem",
              color: "#191919",
              fontWeight: "300",
              lineHeight: "1.3",
              backgroundColor: "transparent",
            }}
          >
            {title}
          </h1>
          <p
            style={{
              fontStyle: "italic",
              fontSize: "1.3em",
              opacity: "1",
              color: "#616A6B",
              textTransform: "capitalize",
            }}
          >
            {description}
          </p>
          <img
            src={image}
            className={`cursor cursor-${projectId}`}
            ref={cursorRef}
            alt={`cursor-${title}`}
          />
        </a>
      </div>
    </div>
  );
}
